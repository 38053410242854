let CognitoRegion = 'ap-northeast-1';
let CognitoUserPool = 'ap-northeast-1_Lx4RhvYkv'; //dev用
// let CognitoUserPool = 'ap-northeast-1_tzQ9aOidb'; //stg環境向け
// let CognitoUserPool = 'ap-northeast-1_7zV2t6Emz'; //本番環境向け

let CognitoUserPoolClient = '6d3ho1qrpe92cdk3625n9gbe2j'; //dev用
// '732ii710hqff125d5q890hj65'
// let CognitoUserPoolClient = '19rvb8edvpv27qufpnhenfbelc'; //stg環境向け
// let CognitoUserPoolClient = '6h7jdl1s3q53jvnqtt6rocck50'; //本番環境向け

let CognitoDomainPrefix = 'service-dev-user';  //dev用
// let CognitoDomainPrefix = 'service-user-test'; //stg環境向け
// let CognitoDomainPrefix = 'blender-melco'; //本番環境向け

let amplifyConfig = {
    Auth: {
        region: CognitoRegion,
        userPoolId: CognitoUserPool,
        userPoolWebClientId: CognitoUserPoolClient,
        oauth: {
            domain: `${CognitoDomainPrefix}.auth.${CognitoRegion}.amazoncognito.com`,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'https://settingmfa.service.sandbox.blender-melco.com',
            redirectSignOut: 'https://settingmfa.service.sandbox.blender-melco.com/logOut',
            // redirectSignIn: 'http://localhost:3000',
            // redirectSignOut: 'http://localhost:3000/logOut',
            // redirectSignIn: 'https://myaccount.service.sandbox.blender-melco.com', //dev用
            // redirectSignOut: 'https://myaccount.service.sandbox.blender-melco.com/logOut', //dev用
            // redirectSignIn: 'https://myaccount.service.lite.blender-demo.com', //旧stg環境ドメイン向け
            // redirectSignOut: 'https://myaccount.service.lite.blender-demo.com/logOut', //旧stg環境ドメイン向け
            //redirectSignIn: 'https://myaccount.platform.stg.blender-melco.com', //stg環境ドメイン向け
            //redirectSignOut: 'https://myaccount.platform.stg.blender-melco.com/logOut', //stg環境ドメイン向け
            // redirectSignIn: 'https://myaccount.platform.service.blender-melco.com', //本番環境ドメイン向け
            // redirectSignOut: 'https://myaccount.platform.service.blender-melco.com/logOut', //本番環境ドメイン向け
            responseType: ['token']
        },
        "federationTarget": "COGNITO_USER_POOLS"
    },
    API: {  
        endpoints: [
          {
            name: 'api_url_base',
            endpoint: 'https://bahsdsoqjh.execute-api.ap-northeast-1.amazonaws.com/dev',
            // endpoint: 'https://bx2474j063.execute-api.ap-northeast-1.amazonaws.com/dev', //dev用
            // endpoint: 'https://3itefai1f3.execute-api.ap-northeast-1.amazonaws.com/poc', //旧stg環境ドメイン向け
            // endpoint: 'https://kc08vin1wc.execute-api.ap-northeast-1.amazonaws.com/poc', //stg環境ドメイン向け
            // endpoint: 'https://vrbg6fd8x3.execute-api.ap-northeast-1.amazonaws.com/prod', //本番環境ドメイン向け
            custom_header: async () => { 
              return { Authorization : 'token' } 
           }
          }
        ]
      }
};
export default amplifyConfig;